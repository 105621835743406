<template>
  <v-row class="mr-0" id="step2-right-page">
    <v-col v-if="nameField" cols="6" class="pr-6">
      <v-text-field placeholder="First Name" hide-details="auto" v-model.trim="firstName"></v-text-field>
    </v-col>
    <v-col v-if="nameField" cols="6" class="pr-6">
      <v-text-field placeholder="Last Name" hide-details="auto" v-model.trim="lastName"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field id="search-address" hide-details="auto" placeholder="Address Line 1"  v-model="model.address1"></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field  placeholder="Suburb" hide-details="auto" id="suburb" v-model="model.suburb"></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field  placeholder="State" hide-details="auto" id="state" v-model="model.state"></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field placeholder="Postcode" hide-details="auto" id="postcode" name="postcode" v-model="model.postcode"></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field placeholder="Country" hide-details="auto" id="country" v-model="model.country"></v-text-field>
    </v-col>
    <v-col cols="6">
        <VuePhoneNumberInput class="phone-input pt-3"  :no-example="true" :error="model.phoneErr" error-color="#DC0050" color="#82B1FF" valid-color="#0032A0" @update="phoneUpdate($event, 'ph')" v-model="model.phone" default-country-code="AU"></VuePhoneNumberInput></v-col>
    <v-col cols="6">
      <v-radio-group dense v-model="model.gender"  hide-details="auto" row>
        <v-radio label="Male" class="mr-2" value="male"></v-radio>
        <v-radio label="Female" class="mr-0" value="female"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="6">
        <VuePhoneNumberInput class="mobile-input pt-3" :no-example="true" :error="model.mobileErr" error-color="#DC0050" color="#82B1FF" valid-color="#0032A0" @update="phoneUpdate($event, 'mob')" v-model="model.mobile" default-country-code="AU"></VuePhoneNumberInput>
    </v-col>
    <v-col cols="6">
      <v-text-field hide-details="auto" placeholder="Email" v-model="model.email"
          :error-messages="$helpers.errorMsg('email', valid.model.email, 'Email')"></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-switch
          v-model="model.subscribe_news" dense
          label="Subscribe to Light News"
          hide-details
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from "lodash";

export default {
  name: "stepTwo",
  props: ["v","nameField"],
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      valid: {}
    }
  },
  computed: {
    model: {
      get() {
        return this.$store.getters["model"];
      },
      set(newValue) {
        return this.$store.commit("setModel", newValue);
      },
    },
    firstName: {
      get() {
        return this.model.first_name ? _.startCase(_.toLower(this.model.first_name)) : '';
      },
      set(newValue) {
        this.model.first_name = newValue ? newValue.trim() || '' : '';
      },
    },
    lastName: {
      get() {
        return this.model.last_name ? _.startCase(_.toLower(this.model.last_name)) : '';
      },
      set(newValue) {
        this.model.last_name = newValue ? newValue.trim() || '' : '';
      }
    }
  },
  created() {
    this.model = {...{
        first_name: "",
        last_name: "",
        address1: "",
        state: "",
        postcode: "",
        gender: "",
        subscribe_news: false,
        countryCode: {
            code: "61",
        },
        phoneErr: false,
        mobileErr: false,
      }, ...this.model};
    this.valid = this.v;
  },
  mounted() {
    (document.getElementsByClassName("select-country-container")[0]).style.maxWidth = '90px';
    (document.getElementsByClassName("select-country-container")[0]).style.minWidth = '90px';
    (document.getElementsByClassName("select-country-container")[1]).style.maxWidth = '90px';
    (document.getElementsByClassName("select-country-container")[1]).style.minWidth = '90px';
    (document.getElementsByClassName("country-selector__label")[0]).style.display = 'none';
    (document.getElementsByClassName("country-selector__label")[1]).style.display = 'none';
    (document.getElementsByClassName("country-selector__country-flag")[0]).style.top = '15px';
    (document.getElementsByClassName("country-selector__country-flag")[1]).style.top = '15px';
    (document.getElementsByClassName("country-selector__toggle")[0]).style.top = 'calc(45% - 10px)';
    (document.getElementsByClassName("country-selector__toggle")[1]).style.top = 'calc(45% - 10px)';
    (document.getElementsByClassName("country-selector__input")[0]).style.paddingTop = '0px';
    (document.getElementsByClassName("country-selector__input")[1]).style.paddingTop = '0px';
    (document.getElementsByClassName("country-selector")[0]).getElementsByTagName('label')[0].style.display = 'none';
    (document.getElementsByClassName("select-country-container")[0]).style['min-width'] = '95px';
    const self = this;
    const phoneEl = (document.getElementsByClassName("phone-input")[0]).getElementsByClassName('input-phone-number')[0];
    phoneEl.getElementsByTagName('input')[0].placeholder = phoneEl.getElementsByTagName('label')[0].innerText = 'Phone';
    const mobileEl = (document.getElementsByClassName("mobile-input")[0]).getElementsByClassName('input-phone-number')[0];
    mobileEl.getElementsByTagName('input')[0].placeholder = mobileEl.getElementsByTagName('label')[0].innerText = 'Mobile';

    const searchFieldSelector = $('#search-address');

    // Address Capture options and initialization
    const address = new ContactDataServices.address({
        layout: 'IntuitiveDefault',
        token: window.VUE_APP_EXPERIAN_API_KEY,
        elements: {
            input: searchFieldSelector[0],
            // Hard-coded country if only single country supported.
            countryList: {
                value: 'AUS'
            }
        }
    });
    address.events.on("post-formatting-search", function(item){
      const address = item.address;
      self.model.address1 = `${address[0].addressLine1} ${address[1].addressLine2} ${address[2].addressLine3}`;
      self.model.suburb = address[3].locality;
      self.model.state = address[4].province;
      self.model.postcode = address[5].postalCode;
      self.model.country = address[6].country;
    });
  },
  methods: {
    phoneUpdate(data, field) {
      if (field === 'ph') {
        if (!data.isValid && this.model.phone) {
          this.model.phoneErr = true;        
        } else {
          this.model.phoneErr = false;
        }
      } else {
        if (!data.isValid && this.model.mobile) {
          this.model.mobileErr = true;        
        } else {
          this.model.mobileErr = false;
        }
      }
    }
  }
};
</script>
